export default function (target: HTMLImageElement, path: string) {
  if (typeof path === 'undefined') return target.src;
  if (
    typeof window !== 'undefined' &&
    !!window &&
    'IntersectionObserver' in window &&
    target
  ) {
    const fallback: string = target.src;
    const optimalImage = path;
    let observer: IntersectionObserver;
    const imageObserver = (entries: Array<IntersectionObserverEntry>) => {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          const lazyImage: HTMLImageElement = <HTMLImageElement>entry.target;
          let error = false;
          try {
            const image = new Image();
            image.src = optimalImage;
            image.onerror = (e) => {
              image.onerror = null;
              lazyImage.src = fallback;
              error = true;
              return;
            };
            lazyImage.src = error ? fallback : optimalImage;
            observer.unobserve(target);
          } catch (e) {
            lazyImage.src = fallback;
            observer.unobserve(target);
            console.error(e);
          }
        }
      });
    };
    observer = new IntersectionObserver(imageObserver, {
      threshold: 0.00001,
    });
    observer.observe(target);
  }
}
